<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4>Social-Media-Konten anbinden</h4>
          </div>
          <div class="card-body">
            <p>
              Verbinden Sie Ihre Social-Media-Konten mit unserer Plattform, um Inhalte direkt zu planen, zu veröffentlichen und die Leistung Ihrer Beiträge zu analysieren. Hier finden Sie eine Anleitung und Antworten auf häufig gestellte Fragen.
            </p>

            <div class="row">
              <div class="col-lg-6">
                <h5>Schritt-für-Schritt-Anleitung</h5>
                <ol>
                  <li>Navigieren Sie zur Seite "Social Media Konten" in Ihrem Dashboard.</li>
                  <li>Wählen Sie das Netzwerk, das Sie verbinden möchten, indem Sie auf "Mit [Netzwerk] verbinden" klicken.</li>
                  <li>Folgen Sie den Anweisungen, um sich bei Ihrem Social-Media-Konto anzumelden und die erforderlichen Berechtigungen zu erteilen.</li>
                  <li>Nach erfolgreicher Verbindung sehen Sie den Status Ihrer Anbindung und können beginnen, Beiträge direkt über unsere Plattform zu planen und zu veröffentlichen.</li>
                </ol>
              </div>
              <div class="col-lg-6">
                <h5>Häufig gestellte Fragen (FAQ)</h5>
                <ul class="list-group">
                  <li class="list-group-item">
                    <strong>Was passiert, wenn ich die Berechtigungen widerrufe?</strong>
                    <p>Wenn Sie die Berechtigungen widerrufen, können Sie keine Beiträge mehr über unsere Plattform veröffentlichen, bis Sie die Anbindung erneut durchführen und die Berechtigungen wieder erteilen.</p>
                  </li>
                  <li class="list-group-item">
                    <strong>Kann ich mehrere Konten desselben Netzwerks verbinden?</strong>
                    <p>Ja, Sie können mehrere Konten desselben Netzwerks verbinden, indem Sie den Vorgang für jedes Konto separat durchführen.</p>
                  </li>
                  <li class="list-group-item">
                    <strong>Wie kann ich eine Verbindung zu einem Netzwerk trennen?</strong>
                    <p>Gehen Sie zur Seite "Social Media Konten", finden Sie das Netzwerk, das Sie trennen möchten, und klicken Sie auf "Verknüpfung aufheben".</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4>Vorteile der KI-gestützten Beitragserstellung</h4>
          </div>
          <div class="card-body">
            <p>
              Unsere Plattform nutzt künstliche Intelligenz, um Ihnen beim Erstellen wirkungsvoller Social-Media-Beiträge zu helfen. Durch die Verwendung von KI können Sie:
            </p>
            <ul>
              <li>Inhalte generieren, die auf die Interessen Ihres Publikums zugeschnitten sind.</li>
              <li>Ihre Reichweite und Engagement durch optimierte Postings erhöhen.</li>
              <li>Zeit sparen, indem Sie schnell und effizient qualitativ hochwertige Inhalte erstellen.</li>
            </ul>
            <p>Integrieren Sie KI in Ihren Workflow, um Ihre Social-Media-Strategie auf die nächste Stufe zu heben.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4>Erstellen eines Beitrags mit unserem KI-Editor</h4>
          </div>
          <div class="card-body">
            <p>
              Unser KI-Editor vereinfacht den Prozess der Beitragserstellung. Folgen Sie dieser einfachen Anleitung, um einen Beitrag zu erstellen:
            </p>
            <ol>
              <li>Öffnen Sie den KI-Editor auf der Seite <strong>Beiträge erstellen</strong>.</li>
              <li>Wählen Sie die Social-Media-Plattform(en), auf denen der Beitrag veröffentlicht werden soll.</li>
              <li>Geben Sie Ihren Beitragstext ein und nutzen Sie die KI-Funktionen, um Vorschläge für Inhalte zu erhalten.</li>
              <li>Passen Sie den generierten Inhalt nach Bedarf an und planen Sie die Veröffentlichung.</li>
            </ol>
            <p>Mit nur wenigen Klicks können Sie so ansprechende und effektive Beiträge für Ihre Zielgruppe erstellen.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialMediaHelpPage',
};
</script>

<style scoped>
.row {
  margin-bottom: 20px;
}

.card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-header {
  background-color: #f8f9fa;
  padding: 16px;
  border-bottom: 1px solid #eaeaea;
}

.list-group {
  border-radius: 0.25rem;
}

.list-group-item {
  border: 1px solid #dee2e6;
  margin-bottom: -1px;
  background-color: #fff;
  padding: 10px 15px;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.faq-section {
  background-color: #f1f3f5;
  padding: 20px;
  border-radius: 5px;
}

.faq-section h5 {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .col-lg-6, .col-lg-12 {
    padding: 0 15px;
  }
}
</style>
